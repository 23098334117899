
import { computed, defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import type { Moment } from 'moment' 

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<Moment>,
    }
  },
  emits: [
    'update:value',
    'open-change',
    'panel-change'
  ],
  setup(props, context) {
    const isOpen = ref(false)

    const yearValue = computed({
      get() {
        return props.value
      },
      set(value) {
        context.emit('update:value', value)
      }
    })

    const handleOpenChange = (status: boolean) => {
      isOpen.value = status ? true : false
      context.emit('open-change', status)
    }

    const handlePanelChange = (value: unknown, mode: string) => {
      yearValue.value = value
      isOpen.value = false
      context.emit('panel-change', value, mode)
    }

    return {
      isOpen,
      yearValue,
      handleOpenChange,
      handlePanelChange
    }
  }
})
