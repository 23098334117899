
import { defineComponent, ref } from 'vue';
import type { PropType } from 'vue';
import { useStore } from 'vuex';
import useModel from '@/hooks/useModel';
export default defineComponent({
    name: 'BuSelector',
    props: {
        optionTitle: {
            type: String as PropType<string>,
            default: 'nameEn',
        },
        optionKey: {
            type: String as PropType<string>,
            default: 'nameEn',
        },
        value: {
            type: [String, Number] as PropType<string | number>,
        },
        default: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value'],
    setup(props) {
        const valueModel = useModel(props, 'value');
        const { state } = useStore();
        const buList = ref(
            state.user.buTreeList.filter((item: { nameEn: string }) =>
                ['MBPC', 'AMG', 'VAN', 'G', 'Maybach'].includes(item.nameEn)
            )
        );
        if (props.default && buList.value.length) {
            valueModel.value = buList.value[0][props.optionKey];
        }

        return {
            valueModel,
            buList,
        };
    },
});
