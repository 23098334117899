import { computed, getCurrentInstance } from "vue";

const useModel = (props: any, name: string) => {
  const emit = getCurrentInstance()?.emit;
  return computed({
    get() {
        return props[name]
    },
    set(val) {
        if (emit) {
            emit(`update:${name}`, val)
        }
    },
  });
};
export default useModel;
