import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from "./index";
import axios from "axios";
import type { AxiosResponse } from "axios";
import type {
  ThirdPartyUploadSearchParams,
  ThirdPartyDataError,
  ThirdPartyDataFilename,
  ThirdPartyDataUploadData,
  DataListResponse,
  AreaRuleCategory,
  AreaRule,
  AreaRuleProvince,
  AreaRuleCity,
  AreaRuleParams,
} from "@/views/LocalRegistration/type";
/** Key models set OASIS-16524 */
/**
 * 根据Bu查typeclass
 * @returns
 */
export const getTypclassByBu = () => {
  return fetchPost("rvapi/lr/bussinessConfig/dealerApplicableTypeClassMap");
};
/**
 * 列表
 * @returns
 */
export const keyModelsList = () => {
  return fetchPost("rvapi/lr/keyModel/list");
};
/**
 * 新增
 * @returns
 */
export const keyModelsCreate = (data = {}) => {
  return fetchPost(
    "rvapi/lr/keyModel/create",
    {},
    {
      params: data,
    }
  );
};
/**
 * 删除
 * @returns
 */
export const keyModelsDelete = (id = "") => {
  return fetchPost(
    "rvapi/lr/keyModel/delete",
    {},
    {
      params: {
        id: id,
      },
    }
  );
};
/**
 * 修改
 * @returns
 */
export const keyModelsEdit = (data = {}) => {
  return fetchPost(
    "rvapi/lr/keyModel/edit",
    {},
    {
      params: data,
    }
  );
};

/** restricted city  import OASIS-16522 */
/**
 * typeClass List
 * @returns
 */
export const applicableTypeClassList = () => {
  return fetchGet("rvapi/lr/bussinessConfig/allApplicableTypeClassList");
};
/** restricted city  import OASIS-16522 */
/**
 * typeClass List
 * @returns
 */
export const applicableTypeClassListByBu = (
  params = {}
): Promise<string[] | ""> => {
  return fetchGet("rvapi/lr/bussinessConfig/applicableTypeClassListByBu", {
    params,
  });
};
/**
 * typeClass List
 * @returns
 */
export const getCityListByName = (params = {}) => {
  return fetchGet("rvapi/lr/foundationArea/cityListByName", { params });
};
/**
 * 列表
 * @returns
 */
export const queryRestrictCityByBu = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegRestrictCity/queryRestrictCityByBu", {
    params,
  });
};

/**
 * 获取省的列表
 * @returns
 */
export const getAllProvince = () => {
  return fetchGet("rvapi/lr/foundationArea/allProvince");
};

/**
 * 保存city
 * @returns
 */
export const saveRegRestrictCity = (params = {}) => {
  return fetchPost("rvapi/lr/locRegRestrictCity/saveRegRestrictCity", params);
};

/**
 * 删除city
 * @param id
 * @returns
 */
export const deleteRegRestrictCity = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegRestrictCity/deleteRegRestrictCity", {
    params,
  });
};

/**
 * 获取市的列表
 * @param provinceId
 * @returns
 */
export const getCityListByProvinceId = (params = {}) => {
  return fetchGetMess("rvapi/lr/foundationArea/cityListByProvinceId", {
    params,
  });
};

/**
 * 获取所有城市的列表
 * @returns
 */
export const getAllCitys = () => {
  return fetchGet("rvapi/lr/foundationArea/allCity");
};

/** Third party registration data import OASIS-16523 */
/**
 * 获取年options
 * @returns
 */
export const getAllYears = () => {
  return fetchGet("rvapi/lr/locRegRestrictUpload/findAllYears");
};
/**
 * 根据年获取version options
 * @param year
 * @returns
 */
export const getVersionByYear = (params = {}) => {
  return fetchGet("rvapi/lr/locRegRestrictUpload/findAllVersionByYear", {
    params,
  });
};
/**
 * 获取列表
 * @param year
 * @param version
 * @returns
 */
export const getListByVersionAndYear = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegRestrictUpload/findByYearAndVersion", {
    params,
  });
};
/**
 * 下载模板
 * @returns
 */
export const downloadTemplate = () => {
  return axios.get(`rvapi/lr/locRegRestrictUpload/template`, {
    responseType: "blob",
  });
};
/**
 * 上传
 * @returns
 */
export const uploadRestrictCity = (
  params = {},
  year: string,
  version: string,
  config = {}
): Promise<any> => {
  return fetchPost(
    `rvapi/lr/locRegRestrictUpload/import/${year}/${version}`,
    params,
    {
      ...config,
    }
  );
};

/** import OASIS-16525 */
/**
 * 获取列表
 * @returns
 */
export const getValidityList = (pulbishDate: string) => {
  return fetchGet(`rvapi/lr/validity/detail?publishDate=${pulbishDate}`);
};

/** import OASIS-16526 */
/**
 * 导出列表
 * @returns
 */
export const exportLicenseMappingCityList = (params = {}) => {
  return axios.post(`rvapi/lr/licenseMappingCity/export`, params, {
    responseType: "blob",
  });
};
/**
 * 获取列表
 * @returns
 */
export const getLicenseMappingCityList = (data = {}, params = {}) => {
  return fetchPost("rvapi/lr/licenseMappingCity/page/list", data, {
    params: params,
  });
};
/**
 * 获取城市信息
 * @returns
 */
export const getCityInfo = (params = {}) => {
  return fetchGetMess("rvapi/lr/foundationArea/getCityInfo", { params });
};
/**
 * create
 * @returns
 */
export const createLicenseMappingCity = (data = {}) => {
  return fetchPost("rvapi/lr/licenseMappingCity/create", data);
};
/**
 * edit
 * @returns
 */
export const editLicenseMappingCity = (data = {}) => {
  return fetchPost("rvapi/lr/licenseMappingCity/edit", data);
};
/**
 * edit
 * @returns
 */
export const getLicenseMappingCityDetail = (params = {}) => {
  return fetchGetMess("rvapi/lr/licenseMappingCity/detail", { params });
};

/** Third party registration data import OASIS-16534 OASIS-16536 */
/**
 * 删除
 * @param id
 * @returns
 */
export const thirdPartyUploadDelete = (id: number) => {
  return fetchPost(
    "rvapi/lr/thirdPartyUpload/delete",
    {},
    {
      params: {
        id,
      },
    }
  );
};

/** Third party registration data import OASIS-16528 */
/**
 * add dealer 模糊查询dealerName
 * @param dealerName
 * @returns
 */
export const getDealerByName = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegDealerBasicInfo/queryDealerByName", {
    params,
  });
};
/**
 * typeClass List
 * @param dealerBuList
 * @returns
 */
export const getDealerApplicableTypeClassList = (data = []) => {
  return fetchPost(
    "rvapi/lr/bussinessConfig/dealerApplicableTypeClassList",
    data
  );
};
/**
 * add dealer 勾选typeclass判断blank city
 * @param applicableTypeClass
 * @param province
 * @param city
 * @returns
 */
export const checkApplicableBlank = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegDealerApplicable/checkApplicableBlank", {
    params,
  });
};
/**
 * add dealer 勾选typeclass生成数据
 * @param applicableTypeClass
 * @param region
 * @param province
 * @param city
 * @returns
 */
export const getApplicableDspData = (params = {}) => {
  return fetchGetMess(
    "rvapi/lr/locRegDealerApplicable/queryApplicableDspData",
    { params }
  );
};
/**
 * add dealer save
 * @param applicableTypeClass
 * @param effectiveTime
 * @param status
 * @returns
 */
export const updateLatestDetail = (params: any) => {
  return fetchPost(
    `rvapi/lr/locRegDealerApplicableDetail/updateLatestDetail`,
    params
  );
};

/**
 * add dealer save
 * @param operationType
 * @param specialAreas
 * @param basicInfo
 * @param checkList
 * @param applicables
 * @returns
 */
export const saveBaseInfo = (params = {}) => {
  return fetchPost("rvapi/lr/locRegDealerBasicInfo/saveBaseInfo", params);
};

/** Third party registration data import OASIS-16529 */
/**
 * delete dealer 模糊查询dealerName
 * @param dealerName
 * @returns
 */
export const getLocalDealerByName = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegDealerBasicInfo/queryLocalDealerByName", {
    params,
  });
};
/**
 * delete dealer 模糊查询dealerName
 * @param dealerName
 * @returns
 */
export const searchDealerByCodeAndName = (params = {}) => {
  return fetchGetMess("dapi/dealerInfo/searchDealerByCodeAndName", { params });
};
/**
 * delete dealer 模糊查询dealerCode
 * @param dealerName
 * @returns
 */
export const getLocalDealerByCode = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegDealerBasicInfo/queryLocalDealerByCode", {
    params,
  });
};
/**
 * 模糊查询swtCode
 * @param dealerName
 * @returns
 */
export const getLocalDealerBySwtCode = (params = {}) => {
  return fetchGetMess(
    "rvapi/lr/locRegDealerBasicInfo/queryLocalDealerBySwtCode",
    { params }
  );
};

/**
 * delete dealer 根据dealerId查详情
 * @param dealerName
 * @returns
 */
export const getDealerMainData = (params = {}) => {
  return fetchGetMess("rvapi/lr/locRegDealerBasicInfo/queryDealerMainData", {
    params,
  });
};
/**
 * delete dealer 取消勾选时查询blankcity
 * @param dealerName
 * @returns
 */
export const checkDeleteDealerCauseBlank = (params = {}) => {
  return fetchGetMess(
    "rvapi/lr/locRegDealerApplicable/checkDeleteDealerCauseBlank",
    { params }
  );
};
/**
 * Query focus area list 16530 查询列表
 * @param dealerName
 * @returns
 */
export const searchDealerListByCondition = (params = {}) => {
  return fetchPost(
    "rvapi/lr/locRegDealerBasicInfo/queryDealerListByCondition",
    params
  );
};
/** Query focus area list 16530 查询列表
 * @param dealerName
 * @returns
 */
export const exportDealerListByCondition = (params = {}) => {
  return axios.get("rvapi/lr/locRegDealerBasicInfo/exportInfo", {
    responseType: "blob",
    params,
  });
};
/**
 * Query focus area list 16531 查询列表
 * @param dealerName
 * @returns
 */
export const getDealerLogListByCondition = (params = {}) => {
  return fetchGetMess(
    "rvapi/lr/locRegDealerLog/queryDealerLogListByCondition",
    { params }
  );
};

/**
 * 导出
 * @param filenames
 * @param bu
 * @param beginTime
 * @param endTime
 * @returns
 */
export const thirdPartyUploadExport = (
  params: ThirdPartyUploadSearchParams
): Promise<AxiosResponse<Blob>> => {
  return axios.post("rvapi/lr/thirdPartyUpload/export", params, {
    responseType: "blob",
  });
};
/**
 * 上传
 * @param bu
 * @param file
 * @returns
 */
export const thirdPartyUploadImport = (
  bu: string,
  file: Blob,
  options: Record<string, unknown>
): Promise<ThirdPartyDataError[] | ""> => {
  const params = new FormData();
  params.set("file", file);
  return fetchPost(`rvapi/lr/thirdPartyUpload/import/${bu}`, params, options);
};
/**
 * filename筛选条件列表
 * @param bu
 * @param beginTime
 * @param endTime
 * @returns
 */
export const thirdPartyUploadFilenameList = (
  beginTime: string,
  endTime: string
): Promise<ThirdPartyDataFilename[]> => {
  return fetchGet("rvapi/lr/thirdPartyUpload/listByCreatedAtIn", {
    params: {
      beginTime,
      endTime,
    },
  });
};
/**
 * 列表查询
 * @param params
 * @param current
 * @param size
 * @returns
 */
export const thirdPartyUploadList = (
  params: ThirdPartyUploadSearchParams,
  current: number,
  size: number
): Promise<DataListResponse<ThirdPartyDataUploadData>> => {
  return fetchPost("rvapi/lr/thirdPartyUpload/page/list", params, {
    params: {
      current,
      size,
    },
  });
};
/**
 * 下载模板
 * @returns
 */
export const thirdPartyUploadTemplate = (): Promise<AxiosResponse<Blob>> => {
  return axios.post(
    "rvapi/lr/thirdPartyUpload/template",
    {},
    {
      responseType: "blob",
    }
  );
};

/*** Key models set OASIS-16541 */
export const getCheckingResultData = (params = {}, query: any) => {
  return fetchPost(
    `/rvapi/lr/regCalculationLicensing/getCalLicenCheckResutInfo?current=${query.current}&size=${query.size}`,
    params
  );
};

export const exportCheckingResultData = (
  params = {}
): Promise<AxiosResponse<Blob>> => {
  return axios.post(
    "/rvapi/lr/regCalculationLicensing/exportCalLicenCheckResutInfo",
    params,
    {
      responseType: "blob",
    }
  );
};

/*** Key models set OASIS-16543 */
export const getCalLicenceComparisonInfo = (params = {}) => {
  return fetchPost(
    "/rvapi/lr/regCalculationLicensing/getCalLicenceComparisonInfo",
    params
  );
};

export const exportCalLicenceComparisonInfo = (
  params = {}
): Promise<AxiosResponse<Blob>> => {
  return axios.post(
    "/rvapi/lr/regCalculationLicensing/exportCalLicenceComparisonInfo",
    params,
    {
      responseType: "blob",
    }
  );
};

/*** Key models set OASIS-16543 获取查询选项*/
export const getCalculationRuleSelection = (
  bu: string
): Promise<{ id: number; name: string }[]> => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/getCalculationRuleSelection?bulist=" + bu
  );
};

/*** Key models set OASIS-16541 publish*/
export const savePublish = (params = {}) => {
  return fetchPost("/rvapi/lr/regCalculationPublish/savePublish", params);
};

/*** Key models set OASIS-16541 PublishHistoryList*/
export const getPublishHistoryList = (params = {}) => {
  return fetchPost(
    "/rvapi/lr/regCalculationPublish/getPublishHistoryList",
    {},
    { params }
  );
};

/*** Key models set OASIS-16537 查询列表*/
export const getExemptionList = (params = {}) => {
  return fetchPost("/rvapi/lr/exemptionRuleBasic/page/list", params);
};
/*** Key models set OASIS-16537 删除表格数据*/
export const deleteExemptionApi = (id: string) => {
  return fetchPost("/rvapi/lr/exemptionRuleBasic/delete?id=" + id);
};
/*** Key models set OASIS-16537 下载vinList*/
export const downloadExportVinListData = (
  params = {}
): Promise<AxiosResponse<Blob>> => {
  return axios.post("rvapi/lr/exemptionRuleBasic/exportVinListData", params, {
    responseType: "blob",
  });
};
/*** Key models set OASIS-16537 下载BasicData*/
export const downloadExportBasicData = (
  params = {}
): Promise<AxiosResponse<Blob>> => {
  return axios.post("rvapi/lr/exemptionRuleBasic/exportBasicData", params, {
    responseType: "blob",
  });
};

/*** Key models set OASIS-16538 view获取信息*/
export const getDetail = (params = {}) => {
  return fetchGet("rvapi/lr/exemptionRuleBasic/detail", { params });
};
/*** Key models set OASIS-16538 view获取信息*/
export const CreateExemptionCode = () => {
  return fetchGet("rvapi/lr/exemptionRuleBasic/createExemptionCode");
};

// get customize vin list
export const getCustomizeVinList = (params = {}) => {
  return fetchPost("/rvapi/lr/exemptionRuleVinDetail/customizeVinList", params);
};
// delete exemption vin detail
export const deleteCustomizeVin = (params = {}) => {
  return fetchPost("/rvapi/lr/exemptionRuleVinDetail/delete", {}, { params });
};
// get exemption rule vin list
export const getExemptionRuleVinList = (params = {}) => {
  return fetchPost(
    "/rvapi/lr/exemptionRuleVinDetail/exemptionRuleVinList",
    {},
    { params }
  );
};
// save exemption rule
export const saveContent = (params = {}) => {
  return fetchPost("/rvapi/lr/exemptionRuleBasic/saveExemptionRule", params);
};
// save exemption rule
export const createContent = (params = {}) => {
  return fetchPost("/rvapi/lr/exemptionRuleBasic/createExemptionRule", params);
};
// import excel for exemption rule
// export const importExemptionRuleExcel = (params = {}) => {
//   return fetchPost('/rvapi/lr/exemptionRuleVinDetail/import/{basicId}', params)
// }

/**
 * [CR]车辆上牌情况 Query report data info
 * @returns
 */
export const queryReportDataInfo = (params = {}) => {
  return fetchPost("/rvapi/lr/regLicenseInfo/queryReportDataInfo", params);
};

/** Local registration_Focus area rule set OASIS-16533 */

export const getCategoryList = (): Promise<AreaRuleCategory[]> => {
  return fetchGet("/rvapi/lr/locRegAreaRule/getCategoryList");
};

export const publishDateList = (): Promise<string[]> => {
  return fetchGet("/rvapi/lr/locRegAreaRule/publish/date/list");
};

export const getAreaRuleList = (publishDate: string): Promise<AreaRule[]> => {
  return fetchGet(`/rvapi/lr/locRegAreaRule/list/${publishDate}`);
};

export const dealerApplicableTypeClassMap = (): Promise<
  Record<string, string[]>
> => {
  return fetchPost("/rvapi/lr/bussinessConfig/dealerApplicableTypeClassMap");
};

export const getAreaRuleSelectedProvinces = (
  locId: number
): Promise<AreaRuleProvince[]> => {
  return fetchGet(`/rvapi/lr/locRegAreaRuleBlack/province/list/${locId}`);
};

export const getAreaRuleSelectedCities = (
  locId: number,
  provineId: number,
  provinceCode: string
): Promise<AreaRuleCity[]> => {
  return fetchGet(
    `/rvapi/lr/locRegAreaRuleBlack/city/list/${locId}/${provineId}/${provinceCode}`
  );
};

export const saveAreaRuleInfo = (params: AreaRuleParams) => {
  return fetchPost("/rvapi/lr/locRegAreaRule/saveAreaRuleInfo", params);
};

// 16539 16540 接口
// bu ruleName ruleCode 查询表格数据
export const getCalculationRuleList = (params = {}) => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/getCalculationRuleList",
    {},
    { params }
  );
};
// 根据id 删除一行数据
export const deleteCalculationRule = (params = {}) => {
  return fetchPost("/rvapi/lr/regCalculationRule/delete", {}, { params });
};
//TODO 对应什么需求？
export const getDefaultRule = (params = {}) => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/getDefaultRule",
    {},
    { params }
  );
};
//TODO 对应什么需求？
export const getRegisSelection = (params = {}) => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/getRegisSelection",
    {},
    { params }
  );
};
// Add 的时候添加新的 rulecode
export const createNewRuleCode = () => {
  return fetchPost("/rvapi/lr/regCalculationRule/getRuleCode");
};
//TODO 对应什么需求？
export const getRuleNameVague = (params = {}) => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/getRuleNameVague",
    {},
    { params }
  );
};
// 新建 lr rule
export const getExemptionCodeList = (params = {}) => {
  return fetchPost("/rvapi/lr/exemptionRuleBasic/exemptionCodeList", params);
};
// 新建 lr rule
export const saveCalculationRule = (params = {}) => {
  return fetchPost("/rvapi/lr/regCalculationRule/saveCalculationRule", params);
};
// 更新 lr rule
export const updateCalculationRule = (params = {}) => {
  return fetchPost("/rvapi/lr/regCalculationRule/update", params);
};

// calculation 抽屉接口
export const calculationExecute = (params = {}) => {
  return fetchPost("/rvapi/lr/regCalculationLicensing/execute", params);
};

/** Dealer Checking Result Page OASIS-16545 */
export const getLocRegCollectResults = (
  params = {},
  query = { current: 1, size: 20 }
): Promise<any> => {
  return fetchPost(
    `/rvapi/lr/regCalculationLicensing/getLocRegCollectResults?current=${query.current}&size=${query.size}`,
    params
  );
};

export const exportLocRegCollectResults = (
  bu: string,
  startTime: string,
  endTime: string,
  userDealerCode: string
): Promise<AxiosResponse<Blob>> => {
  return axios.post(
    "/rvapi/lr/regCalculationLicensing/exportLocRegCollectResults",
    {
      bu,
      startTime,
      endTime,
      userDealerCode,
    },
    {
      responseType: "blob",
    }
  );
};

/** Local registration_Registration validity set OASIS-16525 */
export const saveValidity = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/lr/validity/save", params);
};

export const validityPublishDate = (): Promise<string[]> => {
  return fetchGet("/rvapi/lr/validity/publishDate");
};
export const getCalculateDateFrom = (params = {}): Promise<string[]> => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/calculateDateFrom",
    {},
    { params }
  );
};
export const getProcessList = (params = {}): Promise<string[]> => {
  return fetchGet("/rvapi/lr/regCalculationLicensing/processList", { params });
};
export const cancelCalculate = (params = {}): Promise<string[]> => {
  return fetchPost("/rvapi/lr/regCalculationLicensing/cancelCalculate", {}, { params });
};

export const getRegionCalculationRuleSelection = (
  params = {}
): Promise<string[]> => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/getRegionCalculationRuleSelection",
    {},
    { params }
  );
};

export const getGroupCalculationRuleSelection = (
  bulist: string[],
  usage?: "Y"
): Promise<string[]> => {
  return fetchPost(
    "/rvapi/lr/regCalculationRule/getGroupCalculationRuleSelection",
    {},
    {
      params: {
        bulist,
        usage,
      },
    }
  );
};

export const queryBlankDealer = (params = {}) => {
  return fetchPost("rvapi/lr/locRegDealerBasicInfo/queryBlankDealer", params);
};

export const updatePublishType = (params = {}) => {
  return fetchPost(
    "rvapi/lr/regCalculationPublish/updatePublishType",
    {},
    { params }
  );
};
