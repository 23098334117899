
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    nextTick,
    onMounted,
} from 'vue';
import { message } from 'ant-design-vue';
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue';
import YearPicker from '@/views/Payment/components/YearPicker.vue';
import {
    CreateExemptionCode,
    getExemptionList,
    deleteExemptionApi,
    downloadExportVinListData,
    downloadExportBasicData,
} from '@/API/localRegistration';
import { useRouter } from 'vue-router';
import { downloadFromStream } from '@/utils/payment/downloadFile';
import moment from 'moment';
interface SearchType {
    bu: string | null;
    code: string | null;
    eligibleYear: string | null;
    name: string | null;
}
export default defineComponent({
    components: {
        BuSelector,
        YearPicker,
    },
    setup() {
        const searchCon = ref();
        const router = useRouter();
        const searchParam = reactive({
            bu: undefined,
            code: null,
            eligibleYear: null,
            name: null,
        });
        const tableHeight = ref();
        const columns = [
            {
                title: 'Eligible Period',
                dataIndex: 'eligiblePeriod',
                key: 'eligiblePeriod',
                width: 200,
            },
            {
                title: 'Exemption Code',
                dataIndex: 'code',
                key: 'code',
                width: 200,
            },
            {
                title: 'Exemption Name',
                dataIndex: 'name',
                key: 'name',
                width: 200,
            },
            {
                title: 'Exemption Type',
                dataIndex: 'exemptionTypeName',
                key: 'exemptionTypeName',
                width: 200,
            },
            {
                title: 'Applicable Type Class',
                dataIndex: 'applicableTypeClass',
                key: 'applicableTypeClass',
                width: 400,
            },
            {
                title: 'VIN No.',
                dataIndex: 'vinCount',
                key: 'vinCount',
                width: 100,
            },
            {
                title: 'Dealer No.',
                dataIndex: 'dealerCount',
                key: 'dealerCount',
                width: 100,
            },
            {
                title: 'Last Modified By',
                dataIndex: 'updatedBy',
                key: 'updatedBy',
                width: 400,
            },
            {
                title: 'Last Modified Date',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                width: 200,
            },
            {
                title: 'Operation',
                align: 'center',
                slots: { customRender: 'Operation' },
                width: 200,
            },
        ];
        const selectedRowKeys = ref<string[]>([]);
        const tableDataSelectedChange = (keys: string[]) => {
            selectedRowKeys.value = keys;
        };
        const data = ref<any[]>([]);

        const pagination = reactive({
            pageSize: 20,
            currentPage: 0,
            total: 0,
        });

        const visibleExportModal = ref(false);

        const handleToExport = () => {
            visibleExportModal.value = true;
        };

        const reset = () => {
            Object.assign(searchParam, {
                bu: null,
                code: null,
                eligibleYear: null,
                name: null,
            });
        };

        const search = () => {
            getExemptionList({
                current: pagination.currentPage + 1,
                size: pagination.currentPage,
                bu: searchParam.bu,
                code: searchParam.code,
                eligibleYear: searchParam.eligibleYear
                    ? moment(searchParam.eligibleYear).format('YYYY')
                    : null,
                name: searchParam.name,
            }).then((res: any) => {
                data.value = res.content;
                pagination.total = res.totalElements;
            });
        };

        const deleteExemption = (item: any) => {
            deleteExemptionApi(item.id).then((res: any) => {
                if (res === '') {
                    message.success('Delete Successfully!');
                    search();
                }
            });
        };

        const goDetail = (item: any) => {
            const map = {
                1: 'Customize',
                2: 'Exemption Rule',
            };
            router.push({
                path: '/localRegistration/dataUpload/exemption/edit/',
                query: {
                    bu: searchParam.bu,
                    code: item.code,
                    basicId: item.id,
                    dataType: item.dataType,
                    activeKey: map[item.dataType],
                    isView: 'true',
                },
            });
        };

        const download = async (type: string) => {
            console.log(type);
            let res;
            if (type === 'vinList') {
                res = await downloadExportVinListData(searchParam);
            } else {
                res = await downloadExportBasicData(searchParam);
            }
            downloadFromStream(res);
            visibleExportModal.value = false;
        };
        //更改每页size
        const changePageSize = (page: number, pagesize: number) => {
            pagination.pageSize = pagesize;
            pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
            search();
        };

        //更改分页组件，包括：直接跳转到指定页，上一页下一页
        const changePagination = (page: number, pagesize: number) => {
            pagination.pageSize = pagesize;
            pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
            search();
        };

        const reSearch = () => {
            pagination.currentPage = 1;
            search();
        };

        //添加按钮事件
        const addRule = () => {
            CreateExemptionCode().then((res: any) => {
                router.push({
                    path: '/localRegistration/dataUpload/exemption/add/',
                    query: {
                        bu: searchParam.bu,
                        code: res,
                    },
                });
            });
        };

        nextTick(() => {
            (document.getElementsByClassName(
                'content-container'
            )[0] as any).style.height =
                window.innerHeight -
                220 -
                searchCon.value.$el.scrollHeight +
                'px';
            const height = (document.getElementsByClassName(
                'content-container'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = heightNum - 110;
        });

        onMounted(() => {
            search();
        });

        return {
            addRule,
            tableHeight,
            searchCon,
            reSearch,
            changePagination,
            changePageSize,
            pagination,
            download,
            deleteExemption,
            goDetail,
            reset,
            search,
            ...toRefs(searchParam),
            columns,
            selectedRowKeys,
            tableDataSelectedChange,
            data,
            visibleExportModal,
            handleToExport,
        };
    },
});
